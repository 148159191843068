import './contact_item.css';

const ContactItem = ({img, html_content}) => {
    return(
        <div className="contact-item-container">
            <div className="blur"></div>
            <div className="icon"><img src={img} alt="" className='icon-img'/></div>
            <div className="content">
                {html_content}
            </div>
        </div>
    );
}

export default ContactItem;