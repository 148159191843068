import {Outlet} from "react-router-dom";
import logo from './pages/img-content/logo_savochka.png'
import insta_logo from './pages/img-content/instagram-logo.png'
import { Link } from "react-router-dom";
import "./Layout.css";
import './pages/page-items/footer.css';
import { useEffect, useState } from "react";
import { HashLink } from 'react-router-hash-link';

const Layout = () => {
const [HamburgerOpen,setHamburgerOpen] = useState(false);
    useEffect(()=>{
        document.body.style = HamburgerOpen ? "overflow:hidden":"overflow:visible";
    },[HamburgerOpen]);


    const toogleHamburger = () => {
        setHamburgerOpen(!HamburgerOpen);
    }
    return (
        <>
        <header>
            
            <Link to="/" className="logo-container">
                <img src={logo} alt="logo" height="60" width="37" />
                <p id="logo_name1">SAVOCHKA</p>
                <p id="logo_name2">dental clinic</p>
            </Link>

            <nav>
                <ul className="nav-table">
                    <li className="nav-table-item">
                        <Link to="/">Головна</Link>
                        
                    </li>
                    <li className="nav-table-item">
                    <HashLink smooth to="/#contacts_anchor">
                    Контакти
                    </HashLink>
                    </li>
                    <li className="nav-table-item">
                        <Link to="catalog/">Послуги</Link>
                        
                    </li>
                    <li className="nav-table-item">
                        <HashLink smooth to="/#about_anchor">Про нас</HashLink>
                        
                    </li>
                </ul>
            </nav>
            <div className={HamburgerOpen ? "hamburger fixed" : "hamburger"} onClick={(e) => {setHamburgerOpen(!HamburgerOpen)}}>
                <div className={HamburgerOpen ? "hamb-line-closed" : "hamb-line-opened"}></div>
                <div className={HamburgerOpen ? "hamb-line-closed" : "hamb-line-opened"}></div>
                <div className={HamburgerOpen ? "hamb-line-closed" : "hamb-line-opened"}></div>
            </div>
            <div className={HamburgerOpen ? "mobile-nav-container-active fixed" :  "mobile-nav-container-disable"}>
                <ul className="mobile-nav-table">
                        <li className="mobile-nav-table-item">
                            <a href="/"  onClick={(e) => {toogleHamburger(e)}}>Головна</a>
                            
                        </li>
                        <li className="mobile-nav-table-item">
                            <HashLink smooth to="/#contacts_anchor"  onClick={(e) => {toogleHamburger(e)}}>Контакти</HashLink>
                        </li>
                        <li className="mobile-nav-table-item">
                            <Link to="catalog/" onClick={(e) => {toogleHamburger(e)}}>Послуги</Link>
                            
                        </li>
                        <li className="mobile-nav-table-item">
                            <HashLink smooth to="/#about_anchor"  onClick={(e) => {toogleHamburger(e)}}>Про нас</HashLink>
                            
                        </li>
                </ul>
            </div>
        </header>

        <main>
        <Outlet />
        </main>

        <footer>
            <div className="footer-container">
                <ul>
                    <li><a href="/">Головна</a></li>
                    <li><Link to="catalog/">Послуги</Link></li>
                </ul>
                <div className="line"></div>
                <ul className="footer-contacts">
                    <li>Тел: 093 93 777 63</li>
                    <li>Адреса: вулиця Івасюка, 6, Малехів, Львівська область</li>
                </ul>
                <div className="line"></div>
                <div className="footer-social">
                <a href="https://www.instagram.com/savochka.dentalclinic/" target="_blank" rel="noopener noreferrer"  className="footer-social-inside-container">
                <img src={insta_logo} alt="" />
                <p>Наш інстаграм</p>
                </a>
                    
                </div>
                <div className="author-text-container">
                <a href="https://www.linkedin.com/in/valentine-polischuk-b85478206/" target="_blank" className="author-text">Created by: Valentine</a>
                <a href="https://www.flaticon.com/free-icons/dental" target="_blank" className="author-text">Dental icons created by monkik,Freepik,kerismaker,Gregor Cresnar,smashingstocks,Good Ware - Flaticon</a>
                </div>
            </div>
        </footer>
        </>
    );
}

export default Layout;