import header_mark from "../img-content/header-mak.png"
import "./paragraph.css"
const Paragraph = ({text}) => {
return (
    <div className="paragraph-main-container">
        <div className="paragraph-container">
        <img src={header_mark} alt="" width="30" height="30"/>
        <h2>{text}</h2>
    </div>
    </div>
    
);
};

export default Paragraph;