import "./catalogitem.css"
import { motion } from "framer-motion";
const CatalogItem = ({item_name,description="",price, custom_content = false}) => {
    if (item_name == 'konstrukt'){
        custom_content = true;
    }
return (
    <motion.tr
    className="pricelist_item">
        <td style={ custom_content ? {fontWeight:700} : {fontWeight:400}}>{item_name}</td>
        <td style={{color:'#248671',textAlign:'end'}}>{description}</td>
        <td>{price}</td> 
    </motion.tr>
);
};

export default CatalogItem;