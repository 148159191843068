
let price_list_arr = [
    {
      value:"consultation",
       name:"Консульнація",
       description: "",
       pricelist:[
         {item_name:"Консультація", //new
          price:"200 грн",
          description: "доросла"
         },
         {item_name:"", //new
          price:"300 грн",
          description: "дитяча",
         },
         {item_name:"Інфільтраційна анестезія", //new
          price:"250 грн",
          description: "",
         },
         {item_name:"Аплікаційна анестезія", //new
          price:"100 грн",
          description: "",
         },
         {item_name:"Накладання кофедраму при лікуванні", //new
          price:"350 грн",
          description: "для дорослих",
         },
         {item_name:"", //new
         price:"250 грн",
         description: "для дітей",
        },
       ]
    },
    {
       value:"paradontologia",
       name:"Парадонтологія",
       description: "",
       pricelist:[
         {item_name:"Професійна гігієна ротової порожнини", //new
          price:"900 грн",
          description: "ультразвуковим скелером",
         },
         {item_name:"",
          price:"700 грн",
          description: "порошком Air-Flow",
         },
         {item_name:"",
          price:"1500 грн",
          description: "**комбі",
         },
         {item_name:"",//new
          price:"500 грн",
          description: "дитяча, щіточкою",
         },
         {item_name:"",//new
          price:"700 грн",
          description: "дитяча, ультразвуковим скелером + щіточкою",
         },
         {item_name:"Полірування зубів",
          price:"200 грн",
          description: "(на 1 щелепі)",
         },
        //  {item_name:"Обробка слизової порожнини рота",
        //   price:"200 грн",
        //   description: "",
        //  },
        //  {item_name:"Застосування пародонтальної пов'язки",
        //   price:"200 грн",
        //   description: "(на 1 щелепі)",
        //  },
         {item_name:"Ін'єкція лінкоміцину, траумель-С", //new
          price:"250 грн",
          description: "**у вартість входить препарат",
         },
        //  {item_name:"Закритий кюретаж",
        //   price:"300 грн",
        //   description: "**в ділянці 1 зуба",
        //  },
        //  {item_name:"Шинування зубів скловолоконною стрічкою",
        //   price:"500 грн",
        //   description: "",
        //  },
        //  {item_name:"Встановлення декоративного камінчика",
        //   price:"600 грн",
        //   description: "",
        //  },
         
       ]
    },
    {
        value:"caries",
        name:"Лікування ускладненого карієсу",
        description: "",
        pricelist:
        [{item_name:"Вітальна екстирпація пульпи",//new
        price:"200 грн",
        description: "1 канал",
       },
       {item_name:"Девіталізація пульпи",//new
        price:"200 грн",
        description: "",
       },
       {item_name:"Розпломбування кореневого каналу",//new 
        price:"400 грн",
        description: "просте",
       },
       {item_name:"",//new
        price:"600 грн",
        description: "складне",
       },
       {item_name:"Видалення сторонніх предметів кореневого каналу",
        price:"400 грн",
        description: "просте",
       },
       {item_name:"",
        price:"600 грн",
        description: "складне",
       },
       {item_name:"Механічна обробка",//new
        price:"200 грн",
        description: "1 канал",
       },
       {item_name:"",//new update
        price:"150 грн",
        description: "1 канал, для дітей",
       },
       {item_name:"Медикаментозна обробка",//new
        price:"200 грн",
        description: "1 канал",
       },
       {item_name:"",//new edited
        price:"150 грн",
        description: "1 канал, для дітей",
       },
       {item_name:"Пломбування каналу",//new
        price:"200 грн",
        description: "1 канал",
       },
       {item_name:"Пломбування каналу кальцієм",//new edited
        price:"150 грн",
        description: "",
       },
       {item_name:"Пломбування дитячого каналу",//new edited
        price:"200 грн",
        description: "",
       },
      //  {item_name:"Сендвіч-системи",
      //   price:"200 грн",
      //   description: "",
      //  },
      //  {item_name:"Шліфування пломби",
      //   price:"100 грн",
      //   description: "",
      //  },
      //  {item_name:"ЕОД",
      //   price:"100 грн",
      //   description: "",
      //  },
      ]
     },
     {
        value:"konstrukt",
        name:"Ортодонтія",
        description: "",
        pricelist:[
          {item_name:<span style={{fontSize:'24px'}}><strong>Ортодонтія</strong></span>,
        price:"",
        description: '',
        },
        // {item_name:<p style={{lineHeight:"1.6"}}><br/>
        // Фіксація часткової ⛓ брекет ⛓ системи 2:4 від American Orthodontics лікарем стоматологом-ортодонтом Пушаком Станіславом <br/>
        // Даний ортодонтичний апарат, як класичний металевий — дозволяє виправити дефекти прикусу, усунути кривизну окремих зубів або вилікувати складні аномалії зубощелепної системи. <br/>
        // ⛓ Брекет-система 2:4 фіксується, як правило, на різці та жувальні зуби 🦷<br/>
        // <hr></hr>
        // </p>,
        
        // price:"",
        // description: '',
        // },
        {item_name:"Консультація ортодонта + фотопротокол", //new
          price:"400грн",
          description: "",
         },
        {
          item_name:"Брекет-система",
          price:"500$",
          description: 'прості, метал',
        },
        {
          item_name:"",
          price:"650$",
          description: 'самолігуючі, метал',
        },
        {
          item_name:"",
          price:"700$",
          description: 'кераміка, прості',
        },
        {
          item_name:"",
          price:"900$",
          description: 'кераміка, самолігуючі',
        },
        {
          item_name:"Капа від бруксизму",
          price:"1200грн",
          description: '',
        },
       ]
     },
     {
        value:"hirurg",
        name:"Хірургія",
        description: "",
        pricelist:[
          {item_name:"Видалення зуба просте",//new
        price:"600 грн",
        description: "однокореневого зуба",
       },
       {item_name:"Видалення зуба складне",//new
        price:"900 грн",
        description: "багатокореневого зуба",
       },
       {item_name:"Атипове видалення зуба",//new
        price:"2000 грн",
        description: "",
       },
       {item_name:"Видалення молочного зуба",//new
        price:"300 грн",
        description: "",
       },]
     },
     {
        value:"ortopedia",
        name:"Ортопедія/ Укріплюючі конструкції",
        description: "",
        pricelist:[
       {item_name:"Зняття коронки",//new
        price:"100 грн",
        description: "просте",
       },
       {item_name:"",//new
        price:"200 грн",
        description: "складне",
       },
       {item_name:"Цементування коронки",//new
        price:"300 грн",
        description: "просте",
       },
       {item_name:"Коронка пластмасова тимчасова",//new
        price:"700 грн",
        description: "",
       },
      //  {item_name:"Коронка цільнолита",
      //   price:"1200 грн",
      //   description: "",
      //  },
      //  {item_name:"Коронка металева  штампована",
      //   price:"800 грн",
      //   description: "",
      //  },
      //  {item_name:"Фасетка",
      //   price:"800 грн",
      //   description: "",
      //  },
      //  {item_name:"Коронка комбінована 'Бородюк'",
      //   price:"1000 грн",
      //   description: "",
      //  },
       {item_name:"Коронка метало-пластмасова",//new
        price:"1800 грн",
        description: "",
       },
       {item_name:"Коронка металокерамічна",//new
        price:"4000 грн",
        description: "",
       },
       {item_name:"Коронково-коренева вкладка",//new
        price:"900 грн",
        description: "проста",
       },
       {item_name:"",//new
        price:"1100 грн",
        description: "складна",
       },
       {item_name:"Змінний пластиковий протез",//new
        price:"5000 грн",
        description: "",
       },
       {item_name:"Змінний пластиковий протез 'Верткес'",//new
        price:"10000 грн",
        description: "",
       },
      //  {item_name:"Доварювання зуба до протезу",
      //  price:"400 грн",
      //  description: "",
      // },
      // {item_name:"Починка альвеолярного базису",
      //   price:"400 грн",
      //   description: "",
      //  },
      //  {item_name:"Бюгельний протез",
      //   price:"9000 грн",
      //   description: "від",
      //  },
      //  {item_name:"Анкер",
      //   price:"200 грн",
      //   description: "",
      //  },
      //  {item_name:"Штифт титановий",
      //   price:"250 грн",
      //   description: "",
      //  },
       {item_name:"Штифт скловолоконний",//new
        price:"300 грн",
        description: "",
       },
      //  {item_name:"Штифт парапульпарний",
      //   price:"300 грн",
      //   description: "",
      //  },
       ]
     },
     {
        value:"caries_lite",
        name:"Лікування неускладненого карієсу",
        description: "",
        pricelist:[
       {item_name:"Фотополімерний композит",//new
        price:"800 грн",
        description: "пломба",
       },
       {item_name:"",
       price:"1000 грн",
       description: "мала реставрація",//new
      },
      {item_name:"",
       price:"1200 грн",
       description: "середня реставрація",//new
      },
      {item_name:"",
       price:"1600 грн",
       description: "велика реставрація",//new
      },
       {item_name:"Фотополімерний композит на МОЛОЧНИЙ зуб",//new update
        price:"400 грн",
        description: "для дітей, мала реставрація",//new 
       },
       {item_name:"",//new update
        price:"400 грн",
        description: "для дітей, середня реставрація",//new 
       },
       {item_name:"",//new update
        price:"400 грн",
        description: "для дітей, велика реставрація",//new 
       },
      
      //  {item_name:"Склоіномер (KetacMolar, Vitremer)",
      //   price:"450 грн",
      //   description: "дитяча",
      //  },
      //  {item_name:"Лікувальна прокладка",
      //   price:"100 грн",
      //   description: "",
      //  },
       {item_name:"Герметизація постійного зуба",//new update
        price:"500 грн",
        description: "*для дітей,за один зуб",
       },
       {item_name:"Герметизація молочного зуба", //new
        price:"300 грн",
        description: "*для дітей,за один зуб",
       },
       {item_name:"Тимчасова пломба",//new
        price:"100 грн",
        description: "",
       },
       {item_name:"",//new updated
        price:"50 грн",
        description: "дитяча",
       },
      //  {item_name:"Зняття старої пломби",
      //   price:"100 грн",
      //   description: "",
      //  },
       {item_name:"Фторування",//new
        price:"100 грн",
        description: "* для дітей, за один зуб",
       },
      //  {item_name:"Ендодонтичне відбілювання",
      //   price:"1000 грн",
      //   description: "",
      //  },
      //  {item_name:"Сріблення",
      //   price:"150 грн",
      //   description: "",
      //  },
       {item_name:"Відбілювання зубів 'Magic Smile'", //new
        price:"2200 грн",
        description: "",
       },
       {item_name:"Відбілювання зубів 'Magic Smile' + гігієна", //new
        price:"3000 грн",
        description: "",
       },
       ]
     }
];

export default price_list_arr;