import "./homepage.css";
import icon_brush_teeth from './img-content/icon_brush_teeth.png'
import icon_calendar from './img-content/icon_calendar.png'
import icon_caries from './img-content/icon_caries.png'
import icon_drill from './img-content/icon_drill.png'
import icon_good_teeth from './img-content/icon_good_teeth.png'
import icon_healty_teeth from './img-content/icon_healty_teeth.png'
import icon_implant from './img-content/icon_implant.png'
import icon_implant_1 from './img-content/icon_implant-1.png'
import icon_operation from './img-content/icon_operation.png'
import icon_protected_teeth from './img-content/icon_protected_teeth.png'
import icon_tel from './img-content/icon_tel.png'
import ua_helmet from './img-content/ua_helmet.png'
import vaccine_icon from './img-content/vaccine_icon.png'
import IconCard from "./page-items/icon-card";
import about_us1 from './img-content/about_us1.png'
import about_us2 from './img-content/about_us2.png'
import dot_grig from "./img-content/Dot Grid.png"
import dentist_photo from "./img-content/dentist_photo.jpg"
import consultation from './img-content/consultation.png'
import map from './img-content/map.png'
import map_button from './img-content/map_button.png'
import rentgen from './img-content/rentgen.png'


// doctors
import bubes from "./img-content/doctors/bubes.png";
import ogirko from "./img-content/doctors/ogirko.png";
import pushak from "./img-content/doctors/pushak.png";
import savochka from "./img-content/doctors/savochka.png";
import savochka2 from "./img-content/doctors/savochka2.png";
import smutnyi from "./img-content/doctors/smutnyi.png";
import yarmolik from "./img-content/doctors/yarmolik.png";


import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Parallax } from "react-scroll-parallax";
import Banner from "./page-items/banner";
import Paragraph from "./page-items/paragraph";
import DoctorCard from "./page-items/doctor-card";
import ServiceItem from "./page-items/services";
import ContactItem from "./page-items/contact_item";
import { useEffect, useState } from "react";


const BluredBackground = ({blur, w,h,margin=[0,0],left="1px", top="1px"}) => {
    const style = {
        position: 'absolute',
        zIndex: '1',
        filter: `blur(${blur}px)`,
        width: w,
        height: h,
        top:top,
        left:left,
        margin:`${margin[0]}px ${margin[1]}px`, 
        background: '#84E7D2'
    }
return (
    <div style={style}>
    </div>
);
};
const doctors_list = [{
    doctor_name:"Савочка Віктор",
    doctor_status:<span>лікар стоматолог-хірург,імплантолог</span>,
    doctor_img:savochka,
},{
    doctor_name:"Савочка Ліля",
    doctor_status:<span>дитячий та дорослий лікар-стоматолог,<br/>
    терапевт,ендодонтист</span>,
    doctor_img:savochka2,
},{
    doctor_name:"Ярмолик Олег",
    doctor_status:<span>лікар стоматолог-ортопед,терапевт,<br/>ендодонтист</span>,
    doctor_img:yarmolik,
},{
    doctor_name:"Бубес Олена",
    doctor_status:<span>професійний гігієніст</span>,
    doctor_img:bubes,
},{
    doctor_name:"Пушак Станіслав",
    doctor_status:<span>лікар стоматолог ортодонт,хірург,терапевт</span>,
    doctor_img:pushak,
},{
    doctor_name:"Олег Смутний",
    doctor_status:<span>лікар ортопед,терапевт,<br/>ендодонтист</span>,
    doctor_img:smutnyi,
},{
    doctor_name:"Олена Огорілко",
    doctor_status:<span><strong>дитячий</strong>/дорослий лікар стоматолог-терапевт,<br/>ендодонтист</span>,
    doctor_img:ogirko,
}
];

const work_schedule_html_content = 
    <div className="work_schedule-container">
        <p className="work_schedule-maintext">Графік роботи</p>
        <p className="work_schedule-text">
            <strong>пн:</strong> 	09:00–19:00<br/>
            <strong>вт:</strong>	09:00–19:00<br/>
            <strong>ср:</strong>	09:00–19:00<br/>
            <strong>чт:</strong>	09:00–19:00<br/>
            <strong>пт:</strong>	09:00–19:00<br/>
            <strong>сб:</strong>	09:00–13:00<br/>
            <strong>нд:</strong>	Зачинено
        </p>
    </div>
const contacts_html_content = 
<div className="contact-card-container" id="contacts-section_anchor">
    <p className="contact-card-maintext">Контакти</p>

    <p className="contact-card-text">
        <strong>Адреса: </strong> <span style={{color:'rgba(101, 101, 101, 1)'}}> Адреса: вулиця Івасюка, 6, Малехів, Львівська область
        </span>
    </p>
    <p className="contact-card-text">
    <strong>Телефон:</strong> 
    <span style={{color:'rgba(101, 101, 101, 1)'}}> 093 93 777 63 </span>
    </p> 

    <a href="https://www.google.com/maps/place/%D0%B2%D1%83%D0%BB%D0%B8%D1%86%D1%8F+%D0%86%D0%B2%D0%B0%D1%81%D1%8E%D0%BA%D0%B0,+6,+%D0%9C%D0%B0%D0%BB%D0%B5%D1%85%D1%96%D0%B2,+%D0%9B%D1%8C%D0%B2%D1%96%D0%B2%D1%81%D1%8C%D0%BA%D0%B0+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C,+%D0%A3%D0%BA%D1%80%D0%B0%D1%97%D0%BD%D0%B0,+80383/@49.8810246,24.0726855,18z/data=!4m5!3m4!1s0x473adc94917dfaaf:0xfef0d6f992f5a4e9!8m2!3d49.8810246!4d24.0737798?hl=uk"  target="_blank">
    <div className="map-container">
         <img src={map_button} alt="map button" className="map-button" />
        <img src={map} alt="map" />
    </div>
    </a>
    </div>

const Homepage = () => {
    const getDoctors = doctors_list.map((item) => {
            return(<DoctorCard img={item.doctor_img} doctor_name={item.doctor_name} doctor_status={item.doctor_status}/>)
        })
    return (
    <>
    <div className="main-text-area">
         <BluredBackground blur="159" w="429px" h="80%" margin={[-20,0]} left="57vw" />
        <div className="left-side-decoration">
            <div id="blur1"></div>

            <Parallax   
            speed={-12} style={{ zIndex:3 }}
            rotateZ={[20,-16,'easeInOut']} 
            >
                <IconCard icon={vaccine_icon}  width_height="96" margin={[20,0]} rotate="10" 
                />
            </Parallax>
  
            <Parallax  
            // startScroll={0} 
            // easing="easeInOut" 
            // style={{ zIndex:3 }}
            // rotateX= {[0, 10, 'easeInOut']} 
            // rotateZ={[5,-6,'easeInOut']} 
            // translateY={[10,220,'easeInOut']}
            style={{ zIndex:3 }}
            rotateX= {[0, 10, 'easeInOut']} 
            rotateZ={[-15,26,'easeInOut']} 
            speed={-20}
            >
                <IconCard icon={icon_good_teeth}  width_height="81" margin={[30,0]} rotate="347" />
            </Parallax>
            
            
        </div>
        <div className="main-text-container">
            <h1 className="main-text-header">ЗДОРОВ’Я ТВОЇХ ЗУБІВ - <br/>
            У  <span id="main-text-colored">НАДІЙНИХ РУКАХ</span></h1>
            <p className="main-text-secondary">Широкий спектр послуг та виключно якісне 
обслуговування клієнтів</p>
            <div id="main-button-container">
                <a href="#contacts-section_anchor" className="main-button">ЗАПИСАТИСЬ</a>
                <BluredBackground blur="65" w="210px" h="100px" margin={[-20,0]}/> 
            </div>
        </div>
        <div className="right-side-decoration">
            <Parallax 
            speed={-12} 
            style={{ zIndex:3 }}
            rotateZ={[-20,16,'easeInOut']} 
            >
                <IconCard icon={icon_implant}  width_height="96" margin={[20,0]} rotate="348" />
            </Parallax>
            
            <Parallax 
            style={{ zIndex:3 }}
            rotateX= {[0, 10, 'easeInOut']} 
            rotateZ={[15,-36,'easeInOut']} 
            speed={-20}
            >
                <IconCard icon={icon_drill}  width_height="81"margin={[30,0]} rotate="18"
                />
            </Parallax>
           
        </div>
    </div>
    <Banner header_text="Воїнам ЗСУ - безкоштовно!" text="Поки наші хлопці та дівчата обороняють нашу Країну, дають нам можливість прокидатись та працювати — ми мусимо їм віддячувати кожного дня!
    Це найменше, що ми можемо зробити для тих, хто стоїть на захисті" img={ua_helmet} />
    <Paragraph text="Про нас"/>
    {/* <div style={{height:10000}}>test</div> */}

    

    {/* ABOUT US */}
    <div className="about-us-main-container" id="about_anchor">
        <Parallax 
            speed={-11} 
            style={{zIndex:-1}}
            >
            <img src={dot_grig} alt="dot grid" className="dot_grid1" />
        </Parallax>
        <motion.div 
        initial={{opacity:0,x:-30}}
        whileInView={{opacity:1,x:0}}
        viewport={{once:true}}
        transition={{delay:0.4,duration:0.5}}
        style={{width: "100%"}}>
            <div className="about-us-item">
                <img src={about_us1} alt="about us" width="200"  className="about-us-img"/>
                <div className="about-us-text">
                Наша команда - це кваліфіковані спеціалісти широкого профілю. Ми здійснюємо різні види послуг: від детальної консультації до комплексних рішень для здоров’я ваших зубів. Використовуючи лише якісне, професійне обладнання, ми завжди впевнені в результативності та якості наших послуг!
                </div>
            </div>
            <div className="about-us-item rentgen">
                <img src={rentgen} alt="about us" width="200"  className="about-us-img-rentgen"/>
                <div className="about-us-text">
                У нас є сучасна рентген діагностика (Радіовізіографія) в один клік✅

Прицільна рентгенографія зубів проводиться за допомогою спеціального апарату — цифрового радіовізіографа. Його відмінність від звичайного рентгена в тому, що промені, проходячи крізь кісткові тканини, потрапляють не на плівку, а на спеціальний датчик                </div>
            </div>
        </motion.div>

        {/* BANNER 2 */}
        {/* <motion.div 
        initial={{opacity:0,x:-30}}
        whileInView={{opacity:1,x:0}}
        viewport={{once:true}}
        transition={{delay:0.4,duration:0.5}}>
            <div className="about-us-item">
                
                <div className="about-us-text" style={{width:"280px", textAlign:"right"}}>
                Лікуємо виключно в кофердамі і з анестезією, для вашого комфорту та безболісності процедури.
                </div>
                <img src={about_us2} alt="about us" width="200" className='about-us-img' />
            </div>
        </motion.div> */}
    </div>
    


    <Paragraph text="Наші лікарі"/>
    <div className="doctors-cards-section">
        {getDoctors}
    </div>
    <Parallax 
            speed={-11} 
            style={{zIndex:-2, className:"dot_grid2"}}
            >
            <img src={dot_grig} alt="dot grid" className="dot_grid2" />
            </Parallax>
    <div className="services-section" id="services_anchor">
        <Paragraph text="Наші послуги"/>
        
        <div className="services-main-container">
            <motion.div 
            initial={{scale:1,filter: 'brightness(1) drop-shadow(3px 7px 13px rgba(0, 0, 0, 0.0)) '}}
            whileHover={{scale:1.04,filter:"brightness(1.05) drop-shadow(7px 11px 13px rgba(0, 0, 0, 0.22))"}}
            className="consultation-img-container">
            <Link to="/catalog/consultation">
            <img src={consultation} alt="consultation"  className="consultation-img" />
            <p id="consultation-text">консультація</p>
            </Link>
            
            </motion.div>
            <div className="services-items">
                <ServiceItem img={icon_healty_teeth} text="Парадонтологія" link="paradontologia" />
                <ServiceItem img={icon_caries} text="Лікування 
                    ускладненого
                    карієсу" link="caries" />
                <ServiceItem img={icon_brush_teeth} text="Лікування неускладненого карієсу" link="caries_lite" />
                <ServiceItem img={icon_implant_1} text="Ортодонтія" link="konstrukt" />
                <ServiceItem img={icon_operation} text="Хірургія" link="hirurg" />
                <ServiceItem img={icon_protected_teeth} text="Ортопедія / Укріплюючі конструкції" link="ortopedia" />
                
            </div>
            
        </div>
    </div>

    <div className="contacts-section" id="contacts_anchor">
        <div className="contacts-container">
            <ContactItem img={icon_calendar} html_content={work_schedule_html_content}/>
            <ContactItem img={icon_tel} html_content={contacts_html_content}/>
        </div>
    </div>
    </>
);
};

export default Homepage;