import '../homepage.css';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
const ServiceItem = ({img,text,link}) => {
    return(
        <motion.div
        initial={{scale:1,filter:"brightness(1) drop-shadow(7px 11px 13px rgba(0, 0, 0, 0))"}}
        whileHover={{scale:1.04,filter:"brightness(1.1) drop-shadow(7px 11px 11px rgba(0, 0, 0, 0.14))"}}
        >
        <Link
        to={`/catalog/${link}`}
         className="services-item">
            <div className="service-img">
                <img src={img} alt="teeth" />
            </div>
            <p className="service-name">{text}</p>
        </Link>
        </motion.div>
    )
}

export default ServiceItem;