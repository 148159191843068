import './doctor-card.css';

const DoctorCard = ({img,doctor_name,doctor_status}) => {
return (
    <div className="doctor-card-main-container">
        <img src={img} alt="" className='doctor-card-img' />
        <p className="doctor-name">{doctor_name}</p>
        <p className="doctor-status">{doctor_status}</p>
    </div>
);
};

export default DoctorCard;