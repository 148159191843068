import "./icon-card.css";
const IconCard = ({icon, width_height='150',margin=[0,20],rotate='0'}) => {
    console.log(margin);
    const imgStyle = {
        margin: `${margin[0]}px ${margin[1]}px`,
        transform: `rotate(${rotate}deg)`
    }
return (
    <img src={icon} style={imgStyle} alt="" width={width_height} height={width_height} className="icon-card" />
);
};

export default IconCard;