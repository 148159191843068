
import './App.css';
import {Routes, Route} from 'react-router-dom'
import Homepage from './pages/Homepage';
import Layout from './Layout';
import Catalog from './pages/Catalog';
import { ParallaxProvider } from 'react-scroll-parallax';
import { useState } from 'react';

function App() {
  return (
    <>
    <ParallaxProvider>
    <Routes>
      <Route  path='/' element={<Layout/>}>
      <Route index element={<Homepage />}/>
      <Route path='catalog/' element={<Catalog />} />
      <Route path='catalog/:id' element={<Catalog />} />
      </Route>
    </Routes>
    </ParallaxProvider>
    
    </>
  );
}

export default App;
