import { useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import CatalogItem from "./page-items/catalogitem";
import "./catalog.css";
import price_list_arr from "./page-items/price_list";
import { motion , useAnimation } from "framer-motion";

const Catalog = ({selected_value="consultation"}) => {
    const [category,setCategory] = useState(selected_value);
    const [current_list, setCurrentList] = useState([]);
    const tableAnimation= useAnimation();
    const params = useParams();
    
    useEffect(() => {
        if(params.id) {
            setCategory(params.id);
        }
       
        getCategoryItems(category);
    },[]);

    useEffect(() => {
        getCategoryItems(category);
        tableAnimation.start({opacity:[0,1],transition:{duration:0.8}})
    },[category]);

    function getCategoryItems(category) {
        tableAnimation.start({opacity:[1,0],transition:{duration:0.8}})
        let current_category = price_list_arr.find((item)=>{
            return item.value == category;
        });   
        setCurrentList(current_category.pricelist.map(item => {
                return(
                    <CatalogItem item_name={item.item_name} price={item.price} description={item.description}/>
                );
        }));
        };
    function changeStatus(e) {
        setCategory(e.target.value);
      }



     
return (
    <div className="catalog-main-container">
        <div className="select">
            <select name="catalog-selection" id="catalog-selection"
            value={category} onChange={(e) => {changeStatus(e)}}
            >
                <option value="consultation" >Загальне</option>
                <option value="paradontologia" >Парадонтологія</option>
                <option value="caries">Лікування ускладненого карієсу</option>
                <option value="konstrukt" >Ортодонтія</option>
                <option value="hirurg" >Хірургія</option>
                <option value="ortopedia" >Ортопедія \ Укріплюючі конструкції</option>
                <option value="caries_lite" >Лікування неускладненого карієсу</option>
            </select>
            <div className="custom-arrow">
                    <div className="arrow-line-bottom"></div>
                    <div className="arrow"></div>
                </div>
        </div>
        
        <table
        className="catalog-table">
            <motion.tbody
            animate={tableAnimation}
            >
                {current_list} 
            </motion.tbody>
        </table>
    </div>
);
};

export default Catalog;